import { graphql } from 'gatsby';
import get from 'lodash/get';
import Markdown from 'markdown-to-jsx';
import React from 'react';

// Components
import { FilterBar } from 'components';

// Layouts
import { PageLayout, SEOContent } from 'layouts';

// Templates
import { BasicTemplate, TrackChampionsMeetNameTable } from 'templates';

// Types
import { MeetName, Record, Season } from 'src/types';

interface Props {
  data: {
    champions: Record;
    pageContent: any;
  };
  pageContext: {
    meetName: MeetName;
    season: Season;
  };
}

const FILTERS = [
  { title: 'Year', gqlKey: 'year' },
  { title: 'Venue', gqlKey: 'venue' },
  { title: 'Gender', gqlKey: 'gender' },
  { title: 'Event', gqlKey: 'event' },
];

const OutdoorTrackChampionsTablePage = (props: Props) => {
  const { meetName } = props.pageContext;
  const { champions, pageContent } = props.data;
  const markdown = get(pageContent, 'nodes[0].data.markdown_content', '') || '';

  return (
    <SEOContent>
      <PageLayout>
        <BasicTemplate templateTitle={meetName}>
          <>
            <Markdown>{markdown}</Markdown>
            <FilterBar
              data={champions}
              filters={FILTERS}
              render={tableData => (
                <TrackChampionsMeetNameTable data={tableData} />
              )}
            />
          </>
        </BasicTemplate>
      </PageLayout>
    </SEOContent>
  );
};

export default OutdoorTrackChampionsTablePage;

export const query = graphql`
  query($meetName: String) {
    pageContent: allAirtable(filter: { table: { eq: "Champion Table Page" } }) {
      nodes {
        data {
          markdown_content
          sponsors {
            data {
              sponsor_name
              sponsor_url
              attachments {
                localFiles {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    champions: allAirtable(
      filter: {
        table: { eq: "Outdoor Track Champions" }
        data: { meet_name: { eq: $meetName } }
      }
      sort: { fields: data___year, order: DESC }
    ) {
      nodes {
        data {
          year
          result
          team
          champion: champion_or_champions
          gender
          event
          notes
          meet_name
          venue
        }
      }
    }
  }
`;
